<template>
  <div>
    <Header :link-idx="2" />
    <div class="mt80 g-min-height">
      <!---<img class="img-top" src="@/assets/images/img_top2.png" alt="" />-->
      <div class="pt20 w1220">
        <TeamList></TeamList>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
import TeamList from '@/components/teamList/TeamList.vue'
export default {
  name: 'Team',
  components: { Header, TeamList },
  mounted() {
    scrollTo({
      top: 0
    })
  }
}
</script>
<style lang="less" scoped>
.img-top {
  display: block;
  width: 100%;
  height: auto;
}
</style>
